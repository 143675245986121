<template>
  <div class="w-full">
    <TopBar :show-search="false" />

    <main class="mx-auto w-full overflow-hidden md:pr-0">
      <section class="hero-section mb-8 px-10 xl:mb-6" style="background-color: #FFF6F0;">
        <div class="container mx-auto flex w-full flex-col flex-wrap items-center justify-between md:py-16 lg:flex-row lg:flex-nowrap">
          <div class="my-10 lg:w-1/2 xl:w-7/12">
            <h1 class="font-bw-gradual mb-4 text-center text-39px font-bold leading-tight text-slate md:text-5xl lg:text-left">
              Travel reviews you can trust.
            </h1>

            <p class="mb-12 text-center text-xl text-slate lg:text-left">
              We bring together the best travel advice from 100+ leading publications.
            </p>

            <SearchDropdown home />
          </div>
          <div v-if="mainImage" class="-mb-10 w-full max-w-xl md:-mb-32 md:ml-10 lg:w-1/2 xl:w-5/12">
            <img :src="mainImage" data-not-lazy class="w-full lg:h-445px lg:object-contain lg:object-bottom xl:h-auto" alt="Hero">
          </div>
        </div>
      </section>

      <section class="mx:px-8 container mx-auto px-8 py-10 md:py-20">
        <RecentUpdates :venues="recentUpdates" />
      </section>

      <section class="choice-awards overflow-hidden text-slate">
        <div class="choice-awards-container container relative mx-auto">
          <div class="choice-awards-circle absolute left-0 flex flex-col items-start justify-center overflow-hidden rounded-full bg-white pr-12">
            <span class="letter-sp12 mb-3 inline-block text-sm uppercase" style="background: linear-gradient(to top, #F8FDCD 50%, transparent 50%);">
              JUST ANNOUNCED
            </span>
            <div class="font-bw-gradual mb-4 text-3xl font-bold md:text-5xl" style="line-height: 110%;">
              2024 Experts'<br> Choice Awards
            </div>
            <div class="mb-4 text-lg text-gray-dark md:mb-8 md:text-xl">
              The year's best hotels, restaurants and attractions, according to the professionals.
            </div>
            <a
              href="/experts-choice-awards"
              class="letter-sp15 mb-3 inline-block rounded-lg bg-blue px-8 py-2.5 text-sm uppercase text-white hover:bg-blue-dark"
            >See the 2024 winners</a>
          </div>
        </div>
      </section>

      <section class="container mx-auto px-4 py-20 md:px-8">
        <h3 class="font-bw-gradual mb-6 text-center text-2rem font-bold text-slate">
          Explore destinations
        </h3>

        <DestinationsList :destinations="destinations" :show-count="4" class="mb-10" />

        <div class="text-center">
          <NuxtLink to="/explore" class="letter-sp15 inline-block rounded-lg border border-gray-dark px-8 py-3 text-sm uppercase text-gray-dark hover:bg-gray-200">
            All Destinations
          </NuxtLink>
        </div>
      </section>
    </main>

    <footer class="-mt-20">
      <MainFooter />
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAsyncData } from 'nuxt/app'
import { useFeaturedStore } from '~/stores/featured'
import { useCurrenciesStore } from '~/stores/currencies'
import TopBar from '~/components/layout/TopBar'
import MainFooter from '~/components/layout/MainFooter'
import RecentUpdates from '~/components/home/RecentUpdates'
import DestinationsList from '~/components/explore/DestinationsList'
import SearchDropdown from '~/components/search-dropdown/SearchDropdown'
import destinations from '~/config/destinations-front.json'
import { useHead } from '#imports'

export default {
  components: { SearchDropdown, DestinationsList, RecentUpdates, TopBar, MainFooter },

  async setup () {
    const description = 'Reviews of hotels, restaurants and tourist attractions distilled from 70+ leading travel guides, magazines and newspapers.'
    useHead({
      title: 'Hotel Reviews by Tripexpert',
      meta: [
        { hid: 'description', name: 'description', content: description },
        { hid: 'og:description', property: 'og:description', content: description },
      ],
    })

    await useAsyncData('featured', async () => {
      await Promise.all([
        useFeaturedStore().loadFeaturedVenues(),
        useFeaturedStore().loadFeaturedContent(),
      ])
      return true
    })
    // console.log(res.error.value)
  },

  data: () => ({
    showSearchModal: false,
    destinations,
  }),

  computed: {
    ...mapState(useFeaturedStore, {
      recentUpdates: s => s.venues.data,
      nearby: s => s.nearby,
      content: s => s.content.data,
      homePagePhotos: s => s.homePagePhotos,
    }),

    ...mapState(useCurrenciesStore, ['currency']),

    mainImage () {
      return this.homePagePhotos.length && this.homePagePhotos[0].source_url
    },
  },

  methods: {
    ...mapActions(useFeaturedStore, ['loadFeaturedVenues', 'loadFeaturedContent']),
  },
}
</script>

<style lang="scss" scoped>
.container { max-width: 1393px; }

.search-field { box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); }

.choice-awards {
  background: #dfe9ee url('~/assets/images/home/awards-bg.svg') no-repeat;
  background-size: cover;
}

.choice-awards-container { height: 638px; }

.choice-awards-circle { width: 766px; height: 766px; top: -74px; left: -262px; padding-left: 291px; box-shadow: 8px 8px 32px rgba(0,0,0,.15); }

@media (max-width: 767px) {
  .choice-awards-container { height: 618px; }
  .choice-awards-circle { width: 660px; height: 660px; top: -20px; }
  .choice-awards {background-position: right;}
}
@media (max-width: 550px) {
  .choice-awards-container { height: 560px; }
  .choice-awards-circle { width: 600px; height: 600px; top: -20px; left: -276px; }
}
</style>
