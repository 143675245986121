<template>
  <div class="text-slate">
    <h3 class="font-bw-gradual mb-6 text-2rem font-bold">
      Recent updates
    </h3>

    <CarouselType1 v-if="venues.length" :groups-to-scroll="3" :groups-to-show="3">
      <div v-for="venue in venues" :key="venue.id" class="group mb-3 px-3">
        <NuxtLink :to="venue.path" class="relative mb-4 block select-none overflow-hidden rounded pb-62%">
          <div class="absolute inset-0 flex justify-center align-middle">
            <picture>
              <source v-if="venue.photo_rectangular_webp" :srcset="venue.photo_rectangular_webp" type="image/webp">
              <source :srcset="venue.photo_rectangular" type="image/jpeg">
              <img
                draggable="false"
                class="absolute left-0 size-full bg-gray-400 bg-cover object-cover transition duration-200 group-hover:opacity-75"
                :src="venue.photo_rectangular"
                :alt="venue.name"
                loading="lazy"
              >
            </picture>
          </div>
          <div class="absolute right-4 top-4 mr-1 rounded bg-lime px-2 py-1px font-medium">
            {{ Math.floor(venue.tripexpert_score / 10) }}
            <div class="inline-block">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4.5L8.29688 7.34766L9.75 12L6 9.11719L2.25 12L3.70313 7.34766L0 4.5L4.59375 4.5L6 0L7.40625 4.5L12 4.5Z" fill="#262626" />
              </svg>
            </div>
          </div>
        </NuxtLink>
        <div class="mb-2 text-xl font-bold transition duration-200 group-hover:underline">
          <NuxtLink :to="venue.path">
            {{ venue.name }}
          </NuxtLink>
        </div>
        <div class="venue-description mb-2 text-body" v-html="venue.description" />
      </div>
    </CarouselType1>
  </div>
</template>

<script>
import CarouselType1 from '~/components/shared/CarouselType1'

export default {
  components: { CarouselType1 },

  props: {
    venues: { type: Array, required: true },
  },

  data: () => {
    return {
      show: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.venue-description :deep() {
  a {
    @apply text-blue;
    &:hover { @apply underline; }
  }
}
:deep() {
  @media (min-width: 912px) {
    .slick-prev, .slick-next { top: 120px; }
  }
  .slick-prev, .slick-next { top: 75px; }
}
</style>
